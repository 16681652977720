import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColorPickerComponent, Palette} from "../ngx-ttitan-color-picker.interface";

@Component({
  selector: 'lib-ngx-ttitan-color-picker-palette-list',
  template: `<div class="picker-palette-wrapper" *ngIf="pallets.length > 0">
  <div class="palette-links">
    <div
      class="palette-link"
      *ngFor="let palette of pallets"
      [ngClass]="{'selected': (activePalette !== null && activePalette.id == palette.id)}"
      (click)="selectPalette(palette)"
    >
      {{palette.name}}
    </div>
  </div>
  <div class="palette-picker-holder" *ngIf="activePalette !== null && activePalette.colors.length > 0">
    <div
      class="palette-color"
      *ngFor="let color of activePalette.colors"
      [ngStyle]="{
        backgroundColor: color
      }"
      (click)="colorSelected(color)"
    >

    </div>
  </div>
</div>
`,
  styles: [`:host .picker-palette-wrapper .palette-links{display:flex;flex-wrap:wrap;margin-bottom:5px}:host .picker-palette-wrapper .palette-links .palette-link{margin-right:5px;border-radius:3px;padding:2px 4px;border:1px solid #ddd;text-transform:uppercase;font-size:10px;font-family:-apple-system,BlinkMacSystemFont,San Francisco,Roboto,Segoe UI,Helvetica Neue,sans-serif;font-weight:600;background-color:#fff;cursor:pointer}:host .picker-palette-wrapper .palette-links .palette-link.selected{background-color:#5e6bc5;color:#fff}:host .picker-palette-wrapper .palette-picker-holder{height:165px;display:flex;flex-wrap:wrap;align-items:flex-start;justify-content:flex-start;align-content:baseline;overflow-x:hidden;overflow-y:auto}:host .picker-palette-wrapper .palette-picker-holder .palette-color{cursor:pointer;width:20px;height:20px;border:1px solid #ececec;margin-top:1px;margin-right:1px;border-radius:3px}`]
})
export class NgxTTitanColorPickerPaletteListComponent implements OnInit {

  @Input('pallets') public pallets: Array<Palette> = [];
  @Input('context') public _context: ColorPickerComponent;
  @Output('change') public change: EventEmitter<string> = new EventEmitter<string>();

  public activePalette: Palette = null;

  constructor() { }

  ngOnInit() {
  }

  closePalette() {
    this.activePalette = null;
  }

  selectPalette(palette: Palette) {
    this._context.closePicker();
    if(
      this.activePalette == null
    ) {
      this.activePalette = palette;
    } else if (this.activePalette.id !== palette.id) {
      this.activePalette = palette;
    }

  }

  colorSelected(color) {
    this.change.emit(color);
  }

}
